import {dayjs} from '~/helpers/dayjs';

/**
 * Хелпер для работы с датой.
 */
export default class DateHelper {

	/**
	 * Привести дату в формат DD MMMM.
	 *
	 * @param {string} date Дата
	 */
	public static formattedDate(date: string) {
		return dayjs(date).format('DD MMMM');
	}
}
